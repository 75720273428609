import { type TFunction, type TOptions } from 'i18next';
import {
  createContext,
  type FunctionComponent,
  type ReactNode,
  useContext,
  useMemo,
} from 'react';
import {
  I18nextProvider,
  useTranslation as useI18nTranslation,
} from 'react-i18next';

import { type Assortment } from '../types/assortment';
import { type Buying } from '../types/buying';
import { type Common } from '../types/common';
import { type Events } from '../types/events';
import { type Hub } from '../types/hub';
import { type I18n } from '../types/i18n.types';
import { type Insights } from '../types/insights';
import { type Rebalancing } from '../types/rebalancing';
import { type Reorder } from '../types/reorder';
import { type Replenishment } from '../types/replenishment';

type Namespace =
  | 'reorder'
  | 'replenishment'
  | 'rebalancing'
  | 'buying'
  | 'hub'
  | 'assortment'
  | 'events';

type WithValueOptions<T> = TOptions & {
  value: T;
};

type SafeOptions<T> = {
  [K in keyof WithValueOptions<T>]: K extends 'context'
    ? never
    : WithValueOptions<T>[K];
};

type TranslationProps = {
  namespace: Namespace;
  children: ReactNode;
};

type ReplenishmentPaths = PathOf<Replenishment, true>;
export type ReorderPaths = PathOf<Reorder, true>;
export type RebalancingPaths = PathOf<Rebalancing, true>;
type BuyingTypes = PathOf<Buying, true>;
type InsightsTypes = PathOf<Insights, true>;
type HubTypes = PathOf<Hub, true>;
type AssortmentTypes = PathOf<Assortment, true>;
export type CommonPaths = PathOf<Common, true>;
type EventsPaths = PathOf<Events, true>;

interface TranslationPaths {
  replenishment: ReplenishmentPaths;
  reorder: ReorderPaths;
  rebalancing: RebalancingPaths;
  buying: BuyingTypes;
  insights: InsightsTypes;
  hub: HubTypes;
  assortment: AssortmentTypes;
  events: EventsPaths;
}

type NamespaceTFunction<NS extends Namespace> =
  NS extends keyof TranslationPaths
    ? {
        namespace: NS;
        t: TranslateFunction<TranslationPaths[NS]>;
      }
    : never;

type TranslationContext<NS extends Namespace = Namespace> =
  NamespaceTFunction<NS> & {
    lang: string;
    common: TranslateFunction<CommonPaths>;
  };

function baseFunction() {
  return;
}

baseFunction.withValue = function () {
  return;
};

const AutoneTranslationContext = createContext<TranslationContext | undefined>(
  undefined,
);

interface TranslateFunction<Paths, Options = TOptions> {
  (key: Paths, options?: Options): string;
  withValue: <T>(key: Paths, options: SafeOptions<T>) => string;
}

const extendFunction = <Paths extends string>(
  func: TFunction,
): TranslateFunction<Paths> => {
  const extended = func as any as TranslateFunction<Paths>;
  extended.withValue = (key, options) => {
    // @ts-ignore TODO find out why this type is not being recognised correctly
    return func(key as string, { ...options, context: 'value' });
  };
  return extended;
};

const getTranslationFunctions = (i18n: I18n, namespace: Namespace) => {
  const t = extendFunction(i18n.getFixedT(null, namespace));
  const common = extendFunction<CommonPaths>(i18n.getFixedT(null, 'common'));

  return {
    t,
    common,
  };
};

export type MainTranslationFunction<NS extends keyof TranslationPaths> =
  TranslateFunction<TranslationPaths[NS]>;

export type CommonTranslationFunction = TranslateFunction<CommonPaths>;

const AutoneTranslationContextProvider: FunctionComponent<TranslationProps> = ({
  namespace,
  children,
}) => {
  const { i18n } = useI18nTranslation(namespace);
  const { t, common } = useMemo(
    () => getTranslationFunctions(i18n, namespace),
    [i18n.language],
  );

  const value: TranslationContext = {
    namespace,
    t,
    common,
    lang: i18n.language,
  };

  return (
    <AutoneTranslationContext.Provider value={value}>
      {children}
    </AutoneTranslationContext.Provider>
  );
};

export const AutoneTranslation: FunctionComponent<
  TranslationProps & {
    i18n: I18n;
  }
> = ({ namespace, children, i18n }) => {
  return (
    <I18nextProvider i18n={i18n}>
      <AutoneTranslationContextProvider namespace={namespace}>
        {children}
      </AutoneTranslationContextProvider>
    </I18nextProvider>
  );
};

export const useAutoneTranslation = <
  NS extends Namespace,
>(): TranslationContext<NS> => {
  const context = useContext(
    AutoneTranslationContext,
  ) as TranslationContext<NS>;

  if (!context) {
    throw new Error(
      'useAutoneTranslationContext must be used within the AutoneTranslationContextProvider',
    );
  }

  return context;
};
